import React from 'react'
import { MAIN_PHONE_NUMBER, MAIN_PHONE_NUMBER_LINK } from '@constants/index'
import { Container } from '@shared/components/container'
import { scrollToApplicationForm } from '@shared/components/forms/application/cross'
import { FullLogo } from '@shared/components/logo'
import Link from 'next/link'

import { Button } from '../../button'
import { BurgerV2 } from '../shared/burger-v2'
import { TopMenuListV2 } from '../shared/menu-list-v2'

import { IStateProps } from './model'

import styles from './style.module.scss'

export const View = ({
	onOpenPopupMenu,
	EndAdornment,
	hideLinksList = false,
	content,
	disableRedirectOnLogo = false,
}: IStateProps) => {
	const handleClick = () => {
		const evt = new CustomEvent('headerGetCreditClick')
		document.dispatchEvent(evt)
		scrollToApplicationForm()
	}

	return (
		<header className={styles.wrap}>
			<Container className={styles.container}>
				{content ? (
					content
				) : (
					<>
						{disableRedirectOnLogo ? (
							<FullLogo className={styles.logo} />
						) : (
							<Link href='/'>
								<FullLogo className={styles.logo} />
							</Link>
						)}
						<>
							{!hideLinksList && <TopMenuListV2 className={styles.menuList} />}
							<div className={styles.controls}>
								<div className={styles.phone}>
									<a href={`tel:${MAIN_PHONE_NUMBER_LINK}`}>{MAIN_PHONE_NUMBER}</a>
									<span>Звонок по России бесплатно</span>
								</div>
								{!EndAdornment && (
									<Button
										size='s'
										data-gtm-element='header-enter-button-click'
										className={styles.mobileButton}
										onClick={handleClick}
									>
										Получить кредит
									</Button>
								)}
								{!!EndAdornment && (
									<div className={styles.endAdorment}>
										<EndAdornment />
									</div>
								)}
								<BurgerV2 onClick={onOpenPopupMenu} active={false} className={styles.burger} />
							</div>
						</>
					</>
				)}
			</Container>
		</header>
	)
}
