import React, { PropsWithChildren } from 'react'
import { Container } from '@shared/components/container'

import { RowDescriptionApp } from './Sections/RowDescriptionApp'
import { RowFooterMenu } from './Sections/RowFooterMenu'
import { RowWithSocial } from './Sections/RowWithSocial'
import { LoanMkkFooterTemplate } from './Templates/LoanMkk'
import { IOwnProps } from './model'

import styles from './style.module.scss'

export const View = ({
	hideAppDescription,
	hideFooterMenu,
	hideSocials,
	template,
}: PropsWithChildren<IOwnProps>) => (
	<footer className={styles.wrapper}>
		<Container>
			{!hideSocials && <RowWithSocial />}
			{!hideFooterMenu && <RowFooterMenu />}
			{!hideAppDescription && <RowDescriptionApp />}
			{template === 'loanMkk' && <LoanMkkFooterTemplate />}
		</Container>
	</footer>
)
