import { ReactNode, useCallback } from 'react'
import { usePopupMenu } from '@providers/mobile-popup-menu'
import { useWindowSize } from '@shared/hooks/use-window-size'

import { View } from './view'

export const TopMenuV2 = ({
	hideLinksList = false,
	content = null as ReactNode | null,
	disableRedirectOnLogo = false,
}) => {
	const popupMenu = usePopupMenu()
	const { width } = useWindowSize()

	const handleOpenPopup = useCallback(() => {
		popupMenu.setVisibleStatus(true)
	}, [])

	return View({
		content,
		width,
		hideLinksList,
		disableRedirectOnLogo,
		onOpenPopupMenu: handleOpenPopup,
	})
}
