import React, { Fragment } from 'react'
import {
	footerItemsFirst,
	footerItemsFourth,
	footerItemsSecond,
	footerItemsThird,
} from '@shared/menu-items/footer-items-v2'
import { MenuItem } from '@shared/menu-items/model'
import { Paths } from '@shared/menu-items/paths'
import { isNotEmpty } from '@shared/utils/helpers'
import cx from 'classnames'
import Link from 'next/link'
import { useRegions } from 'regions/provider'

import styles from './style.module.scss'

export const RowFooterMenu = () => {
	const regions = useRegions()

	const renderListItem = ({ name, path }: MenuItem, isSubItem?: boolean) => {
		const href =
			path === Paths.sitemap && regions.cityFromUrl ? `/${regions.cityFromUrl}${path}` : path

		return (
			<li className={cx(styles.navItem, { [styles.navSubItem]: isSubItem })} key={name}>
				{name === 'Банкам' ? (
					<a key={name} href={path} target='_blank' rel='noopener noreferrer'>
						{name}
					</a>
				) : (
					<Link key={name} href={href!}>
						{name}
					</Link>
				)}
			</li>
		)
	}

	const renderSubItems = (items: MenuItem[]) => {
		return <ul className={styles.navSubList}>{items.map((item) => renderListItem(item, true))}</ul>
	}

	const renderNavList = (item) => (
		<Fragment key={item.name}>
			{renderListItem(item)}
			{isNotEmpty(item.items) && renderSubItems(item.items)}
		</Fragment>
	)

	return (
		<nav className={styles.nav}>
			<ul className={cx(styles.navList, styles.first)}>{footerItemsFirst.map(renderNavList)}</ul>
			<ul className={cx(styles.navList, styles.second)}>{footerItemsSecond.map(renderNavList)}</ul>
			<ul className={cx(styles.navList, styles.third)}>{footerItemsThird.map(renderNavList)}</ul>
			<ul className={cx(styles.navList, styles.fourth)}>{footerItemsFourth.map(renderNavList)}</ul>
		</nav>
	)
}
