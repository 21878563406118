import React, { PropsWithChildren } from 'react'
import Arrow from '@shared/svg/arrow.svg'
import cx from 'classnames'

import { IStateProps } from './model'

import styles from './style.module.scss'

export const View = ({
	isOpenContent,
	buttonText,
	children,
	onToggleVisibleContent,
	buttonTextAlign = 'left',
	className,
}: PropsWithChildren<IStateProps>) => {
	const extraStyles = {
		justifyContent: buttonTextAlign === 'left' ? 'flex-start' : 'flex-end',
	}

	return (
		<div className={cx(className, { [styles.spoilerOpen]: isOpenContent })}>
			<div
				style={extraStyles}
				className={styles.spoilerButton}
				tabIndex={0}
				role='button'
				onClick={onToggleVisibleContent}
			>
				{buttonText}
				<Arrow className={styles.spoilerArrow} />
			</div>
			<div className={styles.spoilerContent} hidden={!isOpenContent}>
				{children}
			</div>
		</div>
	)
}
