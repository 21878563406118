import React from 'react'
import CrossSVG from '@shared/svg/cross.svg'
import BurgerSVG from '@shared/svg/burger.svg'
import cx from 'classnames'

import { IStateProps } from './model'

import styles from './style.module.scss'

export const View = ({ className, onClick, active }: IStateProps) => (
	<div tabIndex={0} onKeyPress={onClick} onClick={onClick} className={cx(styles.wrap, className)}>
		{active ? <CrossSVG /> : <BurgerSVG />}
	</div>
)
