import { MenuItem } from '@shared/menu-items/model'
import { ExternalPaths, Paths } from '@shared/menu-items/paths'

export const footerItemsFirst: MenuItem[] = [
	{
		name: 'Кредиты',
		path: Paths.root,
	},
	{
		name: 'Сбережения',
		path: Paths.invest,
	},
	{
		name: 'Банкам и финансовым организациям\n',
		path: ExternalPaths.banks,
	},
]

export const footerItemsSecond: MenuItem[] = [
	{
		name: 'Партнёрам',
		path: Paths.partners,
	},
]

export const footerItemsThird: MenuItem[] = [
	{
		name: 'О компании',
		path: Paths.about,
	},
	{
		name: 'Карьера',
		path: ExternalPaths.career,
	},
	{
		name: 'Контакты',
		path: Paths.contacts,
	},
]

export const footerItemsFourth: MenuItem[] = [
	{
		name: 'Документы и раскрытие информации',
		path: Paths.documents,
	},
	{
		name: 'Журнал «Процент»',
		path: ExternalPaths.companyJournal,
	},
	{
		name: 'Проверка простой электронной подписи',
		path: Paths.signature,
	},
]
