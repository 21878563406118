import { useCallback, useRef, useState } from 'react'
import { useOnClickOutside } from '@shared/hooks/use-outside-click'
import { useWindowScrollTop } from '@shared/hooks/use-window-scroll-top'

import { View } from './view'

export const TopMenuLogin = ({ className = '' }) => {
	const [showDropdown, setShowStatus] = useState(false)
	const ref = useRef<HTMLDivElement>(null)

	const close = useCallback(() => {
		setShowStatus(false)
	}, [])

	useOnClickOutside(ref, close)
	useWindowScrollTop(close)

	const toggle = useCallback(() => {
		setShowStatus((flag) => !flag)
	}, [])

	return View({
		ref,
		showDropdown,
		className,
		onAnchorClick: close,
		toggleDropdownVisible: toggle,
	})
}
