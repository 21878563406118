import { useCallback, useMemo } from 'react'

import { useScreenSize } from './use-screen-size'

export interface ISelectObject<T> {
	mobile?: T
	tablet?: T
	desktop?: T
	largeDesktop?: T
	default: T
	extraMobile?: T
}

export const useSelectDevice = <T>(select: ISelectObject<T> = { default: null } as any): T => {
	const { isExtraMobile, isMobile, isTablet, isDesktop, isLargeDesktop } = useScreenSize()

	const getDefaultIfEmpty = useCallback((value) => value ?? select.default, [select])

	return useMemo(() => {
		if (isExtraMobile) {
			return getDefaultIfEmpty(select.extraMobile ?? select.mobile)
		}

		if (isMobile) return getDefaultIfEmpty(select.mobile)
		if (isTablet) return getDefaultIfEmpty(select.tablet)
		if (isDesktop && !isLargeDesktop) return getDefaultIfEmpty(select.desktop)
		if (isLargeDesktop) return getDefaultIfEmpty(select.largeDesktop)

		return select.default
	}, [select, isExtraMobile, isMobile, isTablet, isDesktop, isLargeDesktop])
}
