import { useCallback, useState } from 'react'
import { usePopupMenu } from '@providers/mobile-popup-menu'
import { MenuOwnProps } from '@shared/components/page-layout/model'
import { useScreenSize } from '@shared/hooks/use-screen-size'
import { useWindowScrollTop } from '@shared/hooks/use-window-scroll-top'
import { useWindowSize } from '@shared/hooks/use-window-size'
import { isClient } from '@shared/utils/is-client'

import { View } from './view'

export const FixedMenuV2 = ({
	EndAdornment,
	content,
	hideLinksList,
	disableRedirectOnLogo,
}: MenuOwnProps) => {
	const [show, setVisibleStatus] = useState(false)
	const screensizeParams = useScreenSize()
	const popupMenu = usePopupMenu()
	const { width } = useWindowSize()

	const handleOpenPopup = useCallback(() => {
		popupMenu.setVisibleStatus(true)
	}, [])

	useWindowScrollTop(
		(scrollTop) => {
			if (scrollTop >= 100 && !show) {
				setVisibleStatus(true)
			} else if (scrollTop < 100 && show) {
				setVisibleStatus(false)
			}
		},
		[show],
		true
	)

	if (isClient && show && (screensizeParams.isDesktop || screensizeParams.isTablet)) {
		return View({
			width,
			EndAdornment,
			content,
			screensizeParams,
			disableRedirectOnLogo,
			onOpenPopupMenu: handleOpenPopup,
			hideLinksList,
		})
	}

	return null
}
