import React from 'react'

import styles from './loanMkk.module.scss'

export const LoanMkkFooterTemplate = () => {
	return (
		<div>
			<div className={styles.content}>
				<p>
					© 2012—2022 Общество с ограниченной ответственностью Микрокредитная компания «Магазин
					кредитов» (ООО МКК «Магазин кредитов»)
				</p>
				<br />
				<p>
					Свидетельство Банка России о регистрационном номере записи в государственном реестре
					микрофинансовых организаций: 651303455003905, дата внесения сведений о юридическом лице в
					государственный реестр микрофинансовых организаций: 29 августа 2013 года. ОГРН:
					1126678009380 ИНН/КПП: 6678014749 / 667801001
				</p>
				<br />
				<p>
					Официальный сайт:{' '}
					<a className={styles.link} href='https://loanstore.ru/' target='_blank' rel='noreferrer'>
						https://loanstore.ru
					</a>
				</p>
				<br />
				<p>
					ООО МКК «Магазин кредитов» — член Саморегулируемой организации Союз микрофинансовых
					организаций «Микрофинансирование и развитие» (СРО «МиР»). Дата вступления: 28 сентября
					2015 года. Адрес СРО «МиР»: 107078, город Москва, Орликов переулок, дом 5, строение 1,
					этаж 2, помещение 11. Сайт:{' '}
					<a className={styles.link} href='https://npmir.ru/' target='_blank' rel='noreferrer'>
						https://npmir.ru/
					</a>
				</p>
				<br />
				<p>
					<a
						className={styles.link}
						target='_blank'
						rel='noreferrer'
						href='https://storage.yandexcloud.net/creditclub-frontend/static/docs/Politika-obrabotki-personalnykh-dannykh-OOO-MKK-Magazin-kreditov.pdf'
					>
						Политика обработки персональных данных
					</a>
				</p>
				<br />
				<p>
					<a className={styles.link} target='_blank' rel='noreferrer' href='https://cbr.ru/'>
						Банк России
					</a>
				</p>
				<br />
				<p>
					<a
						className={styles.link}
						target='_blank'
						rel='noreferrer'
						href='https://cbr.ru/registries/microfinance/?utm_source=w&utm_content=page#a_14199'
					>
						Реестр микрофинансовых организаций
					</a>
				</p>
				<br />
				<p>
					<a
						className={styles.link}
						target='_blank'
						rel='noreferrer'
						href='https://www.cbr.ru/reception/#highlight=%D0%B8%D0%BD%D1%82%D0%B5%D1%80%D0%BD%D0%B5%D1%82-%D0%BF%D1%80%D0%B8%D0%B5%D0%BC%D0%BD%D0%B0%D1%8F'
					>
						Интернет-приёмная Банка России
					</a>
				</p>
				<br />
				<p>
					<a
						target='_blank'
						className={styles.link}
						rel='noreferrer'
						href='https://storage.yandexcloud.net/creditclub-frontend/static/docs/mkk/%D0%B1%D0%B0%D0%B7%D0%BE%D0%B2%D1%8B%D0%B5%20%D1%81%D1%82%D0%B0%D0%BD%D0%B4%D0%B0%D1%80%D1%82%D1%8B/%D0%91%D0%B0%D0%B7%D0%BE%D0%B2%D1%8B%D0%B9%20%D1%81%D1%82%D0%B0%D0%BD%D0%B4%D0%B0%D1%80%D1%82%20%D1%83%D1%82%D0%B2.%20%D0%91%D0%B0%D0%BD%D0%BA%D0%BE%D0%BC%20%D0%A0%D0%BE%D1%81%D1%81%D0%B8%D0%B8%2022.06.2017.pdf'
					>
						Базовый стандарт, утверждённый Банком России от 22.06.2017
					</a>
				</p>
				<br />
				<p>
					<a
						target='_blank'
						className={styles.link}
						rel='noreferrer'
						href='https://storage.yandexcloud.net/creditclub-frontend/static/docs/mkk/%D0%B1%D0%B0%D0%B7%D0%BE%D0%B2%D1%8B%D0%B5%20%D1%81%D1%82%D0%B0%D0%BD%D0%B4%D0%B0%D1%80%D1%82%D1%8B/%D0%91%D0%B0%D0%B7%D0%BE%D0%B2%D1%8B%D0%B9%20%D1%81%D1%82%D0%B0%D0%BD%D0%B4%D0%B0%D1%80%D1%82%20%D1%83%D1%82%D0%B2.%20%D0%91%D0%B0%D0%BD%D0%BA%D0%BE%D0%BC%20%D0%A0%D0%BE%D1%81%D1%81%D0%B8%D0%B8%2027.04.2018.pdf'
					>
						Базовый стандарт, утверждённый Банком России от 27.04.2018
					</a>
				</p>
				<br />
				<p>
					<a
						target='_blank'
						className={styles.link}
						rel='noreferrer'
						href='https://storage.yandexcloud.net/creditclub-frontend/static/docs/mkk/%D0%B1%D0%B0%D0%B7%D0%BE%D0%B2%D1%8B%D0%B5%20%D1%81%D1%82%D0%B0%D0%BD%D0%B4%D0%B0%D1%80%D1%82%D1%8B/%D0%91%D0%B0%D0%B7%D0%BE%D0%B2%D1%8B%D0%B9%20%D1%81%D1%82%D0%B0%D0%BD%D0%B4%D0%B0%D1%80%D1%82%20%D1%83%D1%82%D0%B2.%D0%91%D0%B0%D0%BD%D0%BA%D0%BE%D0%BC%20%D0%A0%D0%BE%D1%81%D1%81%D0%B8%D0%B8%2027.07.2017.pdf'
					>
						Базовый стандарт, утверждённый Банком России от 27.07.2017
					</a>
				</p>
				<br />
				<p>
					<a
						target='_blank'
						className={styles.link}
						rel='noreferrer'
						href='https://storage.yandexcloud.net/creditclub-frontend/static/docs/mkk/structure-and-management/sm-2022-10-24.pdf'
					>
						Информация о структуре и составе участников ООО МКК «Магазин кредитов»
					</a>
				</p>
				<br />
				<p>
					Получение займа за 24 часа (один день) возможно при условии одобрения выдачи займа
					инвестором (займодавцем), предоставления полного необходимого пакета документов,
					оформления договорных отношений и состоявшегося обеспечения исполнения обязательств,
					необходимого инвестору, если условие об обеспечении является обязательным. Процентная
					ставка по займу определяется индивидуально и указывается в договоре займа. Иные расходы
					заемщика определяются договорами об оказании услуг.
				</p>
				<br />
				<p>
					Условия микрозайма под залог недвижимости: сумма займа для физических лиц от 200 тыс. до
					500 тыс. рублей РФ, для Юр. лиц и ИП от 200 тыс. до 5 млн. рублей РФ, процентная ставка:
					от 15% до 78% годовых определяется индивидуально для каждого заявителя в зависимости от
					уровня риска и ликвидности предмета залога, срок займа: от 1 до 60 месяцев, дополнительных
					расходов или комиссий не предусмотрено, кроме расходов на изготовление документов,
					необходимых для рассмотрения заявки и страхования риска утраты права собственности
					предмета залога, в случае такого страхования. С 01.11.2019 г. во исполнение пп. «а» п. 11
					ст. 5 Федерального закона №271-ФЗ от 02.08.2019 г. «О внесении изменений в отдельные
					законодательные акты Российской Федерации» не выдаются займы физическому лицу в целях, не
					связанных с осуществлением предпринимательской деятельности, обязательства заемщика по
					которым обеспечены залогом жилого помещения заемщика и (или) иного физического
					лица-залогодателя по такому займу, а также иными видами залога, согласно пп. «а» п.11 ст.5
					Федерального закона №271-ФЗ от 02.08.2019 г.
				</p>
				<br />
				<p>
					Условия микрозайма под залог автотранспорта: сумма займа для физических лиц от 100 тыс. до
					500 тыс. рублей РФ, для Юр. лиц и ИП от 100 тыс. до 5 млн. рублей РФ, процентная ставка:
					от 28,8% до 76% годовых определяется индивидуально для каждого заявителя в зависимости от
					уровня риска и ликвидности предмета залога, срок займа: от 1 до 36 месяцев, дополнительных
					расходов или комиссий не предусмотрено.
				</p>
				<br />
				<p>
					Условия иного займа под залог недвижимости: сумма займа для физических лиц от 500 001
					рублей до 30 000 000 рублей РФ, для Юр. лиц и ИП от 5 000 001 рублей РФ, процентная
					ставка: от 15% до 78% годовых определяется индивидуально для каждого заявителя в
					зависимости от уровня риска и ликвидности предмета залога, срок займа: от 1 до 60 месяцев,
					дополнительных расходов или комиссий не предусмотрено, кроме расходов на изготовление
					документов, необходимых для рассмотрения заявки и страхования риска утраты права
					собственности предмета залога, в случае такого страхования. С 01.11.2019 г. во исполнение
					пп. «а» п. 11 ст. 5 Федерального закона №271-ФЗ от 02.08.2019 г. «О внесении изменений в
					отдельные законодательные акты Российской Федерации» не выдаются займы физическому лицу в
					целях, не связанных с осуществлением предпринимательской деятельности, обязательства
					заемщика по которым обеспечены залогом жилого помещения заемщика и (или) иного физического
					лица-залогодателя по такому займу, а также иными видами залога, согласно пп. «а» п.11 ст.5
					Федерального закона №271-ФЗ от 02.08.2019 г.
				</p>
				<br />
				<p>Не является публичной офертой.</p>
				<br />
				<p>
					Согласно ст.15-19 Федерального закона от 4 июня 2018 года N 123-ФЗ «Об уполномоченном по
					правам потребителей финансовых услуг» потребитель имеет право направить обращение
					финансовому уполномоченному. Служба обеспечения деятельности финансового уполномоченного:
					119017, г. Москва, Старомонетный переулок, дом 3, почтовый адрес службы обеспечения
					деятельности финансового уполномоченного: 119017, г. Москва, Старомонетный переулок, дом
					3, получатель АНО «СОДФУ», тел. 8 (800) 200-00-10,{' '}
					<a
						className={styles.link}
						href='https://finombudsman.ru/'
						target='_blank'
						rel='noreferrer'
					>
						www.finombudsman.ru
					</a>
				</p>
			</div>
		</div>
	)
}
