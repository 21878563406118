import React, { useCallback, useRef } from 'react'
import { useRouterChangeStart } from '@shared/hooks/use-route-change'
import { useWindowScrollTop } from '@shared/hooks/use-window-scroll-top'

import { IOwnProps } from './model'
import { View } from './view'

export const TopSubmenuV2 = ({ items, setActiveItem }: IOwnProps) => {
	const ref = useRef(null)

	const close = useCallback(() => {
		setActiveItem(null)
	}, [])

	useWindowScrollTop(close)
	useRouterChangeStart(close)

	return View({
		ref,
		items,
	})
}
