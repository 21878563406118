import React from 'react'
import Link from 'next/link'

import { IStateProps } from './model'

import styles from './style.module.scss'

export const View = ({ items, ref }: IStateProps) => {
	if (!items) {
		return null
	}

	return (
		<div role='submenu' ref={ref} className={styles.submenu}>
			{items.map((subItem, i) => (
				<div key={`${subItem.name}-${i}`} className={styles.submenuItem}>
					<Link href={subItem.path!}>{subItem.name}</Link>
				</div>
			))}
		</div>
	)
}
