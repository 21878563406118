import React from 'react'
import { MAIN_PHONE_NUMBER } from '@constants/index'
import { SocialsV2 } from '@shared/components/socials-v2'
import FullLogo from '@shared/svg/full-logo.svg'
import Link from 'next/link'
import styled, { CSSObject } from 'styled-components'

export const RowWithSocial = (): JSX.Element => (
	<ViewRoot>
		<ViewCellLogo>
			<Link href='/'>
				<ImageLogo />
			</Link>
		</ViewCellLogo>
		<ViewCellNets>
			<SocialsV2 />
		</ViewCellNets>
		<ViewCellLinks>
			<ViewCellContacts>
				<a href={`tel:${MAIN_PHONE_NUMBER.replace(/\s/g, '')}`}>{MAIN_PHONE_NUMBER}</a>
				<div>Звонки по России бесплатно</div>
			</ViewCellContacts>
		</ViewCellLinks>
	</ViewRoot>
)

const ViewRoot = styled.div(({ theme }) => ({
	display: 'grid',
	gridGap: 40,
	gridTemplateAreas: `
		"logo"
		"socials"
		"phone"
	`,
	[theme.mediaQueries.mediaMinWidthExtraMobile]: {
		gridTemplateAreas: '"logo 1fr" "socials phone"',
		gridTemplateColumns: '1fr 1fr',
		gridRowGap: 40,
		gridColumnGap: 0,
	},
	[theme.mediaQueries.mediaMinWidthTablet]: {
		gridTemplateAreas: '"logo socials phone 1fr"',
		gridTemplateColumns: '1fr 1fr 1fr 1fr',
		gridRowGap: 40,
		gridColumnGap: 0,
	},
}))

const ViewCellLinks = styled.div(() => ({
	gridArea: 'phone',
}))

const ViewCellLogo = styled.div({
	gridArea: 'logo',
	'& a': {
		display: 'flex',
	},
})

const ViewCellNets = styled.div({
	gridArea: 'socials',
	'& svg': {
		width: 24,
		height: 24,
	},
})

const ImageLogo = styled(FullLogo)({
	width: 155,
	height: 40,
	marginBottom: 13,
})

const ViewCellContacts = styled.div(({ theme }) => ({
	flex: 1,
	display: 'flex',
	flexDirection: 'column',
	'& a': {
		...theme.typography.text20_32,
	},
	'& p': {
		...theme.typography.text12_16,
	},
} as CSSObject))
