import React, { PropsWithChildren } from 'react'
import { MobileLoginOptionsProvider } from '@providers/mobile-login-options'
import { MobileMenuProvider } from '@providers/mobile-popup-menu'

import { FooterV2 } from '../footer-v2'
import { IOwnProps as FooterOwnProps } from '../footer-v2/model'
import { MobileMenu } from '../MobileMenu'
import { PageLayoutOwnProps } from '../page-layout/model'
import { TopMenuV2 } from '../top-menu-v2'
import { FixedMenuV2 } from '../top-menu-v2/fixed'

import styles from './style.module.scss'

export const PageLayoutV2 = ({
	children,
	menuProps,
	footerV2Props,
}: PropsWithChildren<PageLayoutOwnProps>): JSX.Element => (
	<MobileMenuProvider>
		<MobileLoginOptionsProvider>
			<div className={styles.wrapper}>
				<TopMenuV2 {...menuProps} />
				<FixedMenuV2 {...menuProps} />
				<MobileMenu />
				<main id='page-content' className={styles.content}>
					{children}
				</main>
				<FooterV2 {...footerV2Props} />
			</div>
		</MobileLoginOptionsProvider>
	</MobileMenuProvider>
)
