import { PropsWithChildren, useCallback, useState } from 'react'

import { IOwnProps } from './model'
import { View } from './view'

export const Spoiler = (props: PropsWithChildren<IOwnProps>) => {
	const [isSpoilerVisible, setSpoilerVisibleStatus] = useState(false)
	const toggle = useCallback(() => setSpoilerVisibleStatus((status) => !status), [])

	return View({
		...props,
		isOpenContent: isSpoilerVisible,
		onToggleVisibleContent: toggle,
	})
}
