import React, { ReactElement } from 'react'
import { ButtonLink } from '@shared/components/button-link'
import { Image } from '@shared/components/image'
import { useSelectDevice } from '@shared/hooks/use-select-device'
import { ExternalPaths } from '@shared/menu-items/paths'
import AppStoreBtn from '@shared/svg/appstore.svg'
import GooglePlay from '@shared/svg/googleplay.svg'
import SignOnlyLogo from '@shared/svg/sign-only-logo.svg'
import cn from 'classnames'

import styles from './style.module.scss'

interface Props {
	TitleComponent?: ReactElement<any, any>
	withLogo?: boolean
	headerStyles?: string
	descriptionStyles?: string
}

export const MobileAppContent = ({
	TitleComponent,
	withLogo = false,
	headerStyles,
	descriptionStyles,
}: Props) => (
	<>
		<div className={cn(headerStyles || styles.header)}>
			{TitleComponent ? (
				TitleComponent
			) : (
				<h2 className={styles.title}>
					Мобильное приложение
					<br /> Credit.Club Деньги
				</h2>
			)}
			{withLogo && <SignOnlyLogo />}
		</div>
		<div className={cn(descriptionStyles)}>Установите на свой телефон</div>
		<div className={styles.footer}>
			<ButtonLink
				size='s'
				target='_blank'
				rel='noopener noreferrer'
				href={ExternalPaths.appStore}
				className={styles.link}
			>
				<AppStoreBtn />
			</ButtonLink>
			<ButtonLink
				size='s'
				target='_blank'
				rel='noopener noreferrer'
				href={ExternalPaths.playMarket}
				className={styles.link}
			>
				<GooglePlay />
			</ButtonLink>
		</div>
	</>
)

export const View = () => (
	<div className={styles.wrapper}>
		<div className={styles.app}>
			<MobileAppContent withLogo />
		</div>
		<div className={styles.img}>
			<Image
				className={styles.image}
				name={useSelectDevice({
					largeDesktop: 'Repose-Isometric-iPhone-12-xl.png',
					default: 'Repose-Isometric-iPhone-12.png',
				})}
				alt='iphone'
			/>
		</div>
	</div>
)
