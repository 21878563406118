import React, { ComponentType } from 'react'
import { ExternalPaths } from '@shared/menu-items/paths'
import BlackVK from '@shared/svg/socials/vk-black.svg'
import BlackYandexDzen from '@shared/svg/socials/yandex-dzen-black.svg'
import BlackYouTube from '@shared/svg/socials/youtube-black.svg'
import cx from 'classnames'

import { IStateProps } from './model'

import styles from './style.module.scss'

interface Social {
	alt: string
	href: string
	IconComponent: ComponentType<any>
}

export const socials: Social[] = [
	{
		alt: 'ВКонтакте',
		href: ExternalPaths.companyVkPage,
		IconComponent: BlackVK,
	},
	{
		alt: 'Youtube',
		href: ExternalPaths.companyYoutubeChannel,
		IconComponent: BlackYouTube,
	},
	{
		alt: 'YandexDzen',
		href: ExternalPaths.companyDzen,
		IconComponent: BlackYandexDzen,
	},
	// {
	// 	alt: 'Одноклассники',
	// 	href: ExternalPaths.companyOdPage,
	// 	IconComponent: NewOK,
	// },
]

export const View = ({ className }: IStateProps) => (
	<div className={cx(styles.wrap, className)}>
		{socials.map(({ alt, href, IconComponent }) => {
			return (
				<a
					data-gtm-element='footer-soc-icon-click'
					aria-label={alt}
					key={alt}
					className={styles.item}
					href={href}
					rel='noopener noreferrer'
					target='_blank'
				>
					<IconComponent />
				</a>
			)
		})}
	</div>
)
