import { useCallback, useEffect, useState } from 'react'
import { useRouter } from 'next/router'

import { View } from './view'

export const TopMenuListV2 = ({ className = '', hideLinksList = false }) => {
	const [activeItem, setActiveItem] = useState<string | null>(null)

	const { pathname } = useRouter()

	const handleToggle = useCallback(
		(name: string) => {
			setActiveItem(name)
		},
		[activeItem]
	)

	const mousemoveHandler = useCallback(({ target }) => {
		if (
			target &&
			typeof target.closest === 'function' &&
			!target.closest('[role="submenu"]') &&
			!target.closest('[role=menubutton]')
		) {
			setActiveItem(null)
		}
	}, [])

	useEffect(() => {
		if (activeItem) {
			document.addEventListener('mousemove', mousemoveHandler)
			return () => {
				document.removeEventListener('mousemove', mousemoveHandler)
			}
		}

		return undefined
	}, [activeItem])

	if (hideLinksList) return null

	return View({
		className,
		activeItem,
		pathname,
		setActiveItem: handleToggle,
	})
}
